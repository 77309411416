.status {
    position: relative;

    &__symbol {
        display: block;
        width: 1rem;
        height: 1rem;
        border-radius: .5rem;
        background-color: var(--color-tertiary);
        position: absolute;
        top: -.4rem;
        right: -.4rem;
    }
}