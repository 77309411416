.live-state {
    display: grid;
    align-items: start;
    gap: 1.6rem;
}

@media only screen and (min-width: 640px) {
    .live-state {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 960px) {
    .live-state {
        grid-template-columns: repeat(4, 1fr);
    }
}