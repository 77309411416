:root {
    --color-body: #f4f4f4;

    --color-body-text: #798795;
    --color-heading-text: #34495e;

    --color-primary: #f16830;
    --color-secondary: #34495e;
    --color-tertiary: #798795;

    --color-success: #11cc6f;
    --color-error: #dd4040;

    --color-black: #222222;
    --color-lightgrey: #f4f4f4;
    --color-white: #ffffff;
}