.nav {
    &--primary {
        >ul {
            display: flex;
            align-items: center;
            gap: 1.6rem;

            >li {
                >a {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: .8rem;
                    line-height: 1;
                    text-decoration: none;
                    color: var(--color-body-text);

                    &:hover {
                        cursor: pointer;
                    }

                    >svg {
                        display: block;
                        width: 3.2rem;
                        height: 3.2rem;
                        padding: .8rem;
                        fill: var(--color-body-text);
                    }

                    >span {
                        display: none;
                    }

                    &.is-active {
                        font-weight: 500;
                        color: var(--color-secondary);

                        >svg {
                            fill: var(--color-secondary);
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .nav {
        &--primary {
            >ul {
                gap: 3.2rem;

                >li {
                    >a {
                        >svg {
                            width: 1.6rem;
                            height: 1.6rem;
                            padding: 0;
                        }

                        >span {
                            display: inline;
                        }
                    }
                }
            }
        }
    }
}