.card {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "header"
        "body"
        "footer";
    background-color: var(--color-white);
    border-radius: .4rem;
    box-shadow: 0 .4rem 1.6rem rgba(#34495e, .1);

    >* {
        min-width: 0;
        min-height: 0;
    }

    &__header {
        grid-area: header;
        padding: .8rem 1.6rem;
        background-color: var(--color-secondary);
        color: var(--color-white);
        border-top-left-radius: .4rem;
        border-top-right-radius: .4rem;
    }

    &__body {
        grid-area: body;
        padding: 1.6rem;
    }

    &__footer {
        grid-area: footer;
    }
}