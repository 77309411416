.qr {
    display: none;
}

@media only screen and (min-width: 1024px) {
    .qr {
        display: block;
        position: fixed;
        bottom: 2.4rem;
        right: 2.4rem;
        background-color: var(--color-white);
        border-radius: .4rem;
        padding: .8rem;
        box-shadow: 0 .4rem 1.6rem rgba(#34495e, .1);

        &__image {
            width: 10rem;
            height: 10rem;
        }
    }
}