.button {
    &__group {
        display: flex;
        flex-wrap: wrap;
        gap: .8rem;
    }
}

.p-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 4rem;
    padding: .8rem 1.6rem;
    background-color: var(--color-tertiary);
    border: 1px solid var(--color-tertiary);
    border-radius: .4rem;
    font-family: Roboto, sans-serif;
    font-size: 1.6rem;
    text-align: center;
    line-height: 1.25;
    color: var(--color-white);

    &:hover {
        cursor: pointer;
    }

    &.button--primary {
        background-color: var(--color-primary);
        border: 1px solid var(--color-primary);
    }

    &.button--secondary {
        background-color: var(--color-secondary);
        border: 1px solid var(--color-secondary);
    }

    &.button--green {
        background-color: var(--color-success);
        border: 1px solid var(--color-success);
    }

    &.button--red {
        background-color: var(--color-error);
        border: 1px solid var(--color-error);
    }
}