*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    width: 100%;
    height: 100%;
    font-size: 62.5%;
}

body {
    width: 100%;
    height: 100%;
    background-color: var(--color-body);
    font-family: Roboto, sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.75;
    color: var(--color-body-text);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    line-height: 1.25;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
}

ol,
ul {
    list-style: none;
}

a {
    color: var(--color-primary);
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

strong {
    font-weight: 500;
}