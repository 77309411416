.form {
    &__list {
        display: grid;
        gap: .8rem;
    }

    &__row {
        display: grid;
        grid-template-columns: 50% auto;
        justify-content: space-between;
    }
}

label {
    color: var(--color-heading-text);
}

textarea {
    display: block;
    width: 100%;
    padding: 1.6rem;
    border-radius: .4rem;
    border: 0;
    background-color: var(--color-lightgrey);
    font-family: Roboto, sans-serif;
    font-size: 1.6rem;
    color: var(--color-body-text);
}

.input__code {
    background-color: var(--color-black);
    font-family: monospace;
    font-size: 1.4rem;
    color: var(--color-white);
}