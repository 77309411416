.kusto-db {
    display: grid;
    gap: 1.6rem;

    >* {
        min-width: 0;
        min-height: 0;
    }
}

@media only screen and (min-width: 1024px) {
    .kusto-db {
        width: 100%;
        height: 100%;
        grid-template-rows: auto 1fr;
    }
}