.brand {
    display: inline-grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    gap: 1.6rem;

    >* {
        min-width: 0;
        min-height: 0;
    }

    &__image {
        width: 4.8rem;
        aspect-ratio: 1 / 1;
    }

    &__label {
        display: none;
    }
}

@media only screen and (min-width: 1024px) {
    .brand {
        &__label {
            display: inline;
            font-size: 2rem;
            font-weight: 500;
            line-height: 1.25;
            color: var(--color-heading-text);

            span {
                display: block;
                font-size: 1.4rem;
                font-weight: 400;
                color: var(--color-body-text);
            }
        }
    }
}