.page {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;

    >* {
        min-width: 0;
        min-height: 0;
    }

    &__body {
        overflow: auto;
        padding: 1.6rem;
    }
}