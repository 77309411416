.header {
    background-color: var(--color-white);
    padding: 1.6rem;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr auto;
    gap: 2rem;
    box-shadow: 0 .4rem 1.6rem rgba(#34495e, .1);

    &__nav {
        justify-self: center;
    }
}

@media only screen and (min-width: 1024px) {
    .header {
        gap: 4rem;
    }
}