.p-datatable {
    th,
    td {
        font-size: 1.4rem;
        line-height: 1.25;
        text-align: left;
    }

    th {
        padding: 1.6rem;
        background-color: var(--color-secondary);
        font-weight: 500;
        color: var(--color-white);
    }

    td {
        padding: .8rem 1.6rem;
    }

    tr {
        &:nth-child(even) {
            background-color: var(--color-lightgrey);
        }
    }
}
